.score {
    margin-bottom: 18.45px;

    .TrackiScore {
        display: flex;
        justify-content: space-between;

        font-size: 12px;
        line-height: 18px;

        .TrackiScoreText {
            display: flex;

            p {
                font-weight: 600;
                font-style: normal;
            }

            img {
                margin-left: 6.83px;
            }
        }

        .TrackiScorePercentage {
            font-weight: 400;
            color: #98A2B3;
        }
    }

    .TrackiScoreTextSub {
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 10.7018px;
            line-height: 16px;

            color: #8391A2; //--gray--500
        }
    }

    .linearDeterminateWrapper {
        margin-top: 12.18px;
    }
}