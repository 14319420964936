.disputeOpenedWrapper {
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    .disputeOpenedData {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 23.48px;

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 9.5px;

            color: #344054; //--Gray--700
        }

        .disputeAwaitingStatus,
        .disputeDueDate {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-transform: lowercase;

            display: inline-block;
            text-align: center;
            border-radius: 16px;
            padding: 4px 12px;
            white-space: nowrap;

            &.disputeDueDate {
                background: #FEF3F2; //--error--50
                color: #B42318; //--error--700
            }
        }

        .disputeAwaitingStatus::first-letter,
        .disputeDueDate::first-letter {
            text-transform: capitalize;
        }

        .disputeSubmitStatus {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            display: inline-block;
            text-align: center;
            border-radius: 16px;
            padding: 4px 12px;
            white-space: nowrap;

            &.disputeSubmitStatus {
                background: #ECFDF3; //--Success--50
                color: #027A48; //--Success--700
            }
        }
    }
}