.sidebarWrapper {
  width: 214px;
  height: inherit;
  padding: 38.44px 23.55px 28.76px 10px;
  border-radius: 10px 0px 0px 10px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .top {
    .name {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      margin-bottom: 14.56px;
    }

    .options {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #475467;
      margin-bottom: 224.56px;

      .option {
        height: 45px;
        margin-bottom: 3px;
        padding: 13.51px 0 13.51px 14.71px;
        width: 179px;
        cursor: pointer;
        &:hover,
        &:focus,
        &:active {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          color: #225ccb;
          background: #e9effa;
          border-radius: 5px;
        }
      }
    }
  }

  .bottom {
    // position: absolute;
    bottom: 0;
    .terms {
      padding-bottom: 22.5px;
      display: flex;
      flex-direction: column;
      gap: 15.5px;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: #475467;
      border-bottom: 1.1808px solid #eaecf0;
    }
    .rights {
      padding-top: 17.42px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #8391a2;
    }
  }
}
