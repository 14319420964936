.disputeWrapper {
    margin: 21px 15px 57px 17px;

    .manageHead {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        h6 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
        img {
            width: 73.85px;
            height: 20px;
        }

        .summaryLogo {
            display: flex;
            gap: 5px;

            img {
                width: fit-content;
            }

            p{
                font-size: 12px;
            }
        }
    }

    .manageFormWrapper{
        .manageFormTabsWrapper{
            .manageFormTabs{
                text-transform: capitalize;
            }

            .integrationTabs {
                display: flex;
                gap: 16px;

                .integrationTab {
                    text-transform: capitalize;
                    border-radius: 0;
                    color: #8391A2;
                    padding: 12px 10px 15px;
                }

                .activeTab {
                    border-bottom: 2px solid #18408E;
                    color: #18408E;
                }
            }
        }

        .formContainer{
            margin-left: 10px;
        }
    }
}