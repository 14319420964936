.trackingJourneyWrapper {
    font-family: 'Inter', sans-serif;

    .trackingJourneyContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        padding: 8px 0;

        p {
            font-size: 12px;
            font-weight: 600;
        }

        span {
            font-size: 12px;
            color: #848484;
        }
    }

    .noTrackingJourney {
        font-size: 12px;
        text-align: center;
        color: #98A2B3;
        padding: 40px 0px;
    }
}