.saveButtonStyle{
    background-color: #1B4AA2 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
     color: #FFF;
     &:hover{
        background-color: #1B4AA2 !important;
     }
}
.linkButton{
   display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: #364152;
    text-transform: capitalize;
    border: 1px solid #E3E8EF;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 6px 1rem;
}