.integrationMainWrapper {
    padding: 29px 44px 10px 31px;
    border-radius: 12px;
    border: 0.5px solid #EAEAEA;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);

    .integrationMain {

        .integrationHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;

            h3 {
                color: #101828;

                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }

            button {
                border-radius: 8px;
                border: 1px solid var(--gray-modern-200, #E3E8EF);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                display: flex;
                gap: 1rem;
                padding: 10px 18px;

                p {
                    text-transform: none;
                    color: #364152; //--gray--modern--700
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }

        .integrationDataWrapper {
            .integratedData {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px 0;
                border-bottom: 1px solid #EAECF0;

                .integratedInfo {
                    display: flex;
                    align-items: flex-start;
                    gap: 5px;

                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;

                    .icon {
                        width: 20.941px;
                        height: 20.941px;
                        background: #e9effa;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    h4 {
                        overflow: hidden;
                        color: #1D2939;
                        text-overflow: ellipsis;
                        font-size: 13px;
                    }

                    p {
                        color: #8391A2;
                        font-size: 11px;
                    }
                }
            }

            .integratedData:last-child {
                border-bottom: 0px;
            }
        }
    }
}