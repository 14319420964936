@import '../../size.mixin.scss';

.connectWrapper {
  width: 75vw;
  display: flex;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  max-width: 1000px;

  @include for-size(sm) {
    flex-direction: column;
    margin: auto;
    gap: 2rem;

    box-shadow: none;
  }

  .connectLeft {
    position: relative;
    width: 50%;
    background: #ffffff;
    border-radius: 0px 12px 12px 0px;
    padding: 87.97px 61.15px 163.03px 68px;

    @include for-size(sm) {
      padding: 20px 20px 30px;
      width: 100%;

      box-shadow: 2px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    .lineConnect {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      color: #1b4aa2;

      * {
        z-index: 1;
      }

      img {
        width: 40px;
        height: 40px;
        padding: 5px;
        background: #ffffff;
        box-shadow: 0px 1.23452px 6.17261px rgba(16, 24, 40, 0.1),
          0px 1.23452px 12.3452px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
      }

      .lineCheck {
        background: #ffffff;
        box-shadow: none;
        border-radius: 0;
        width: 30px;
      }

      .lineCenter {
        margin: 0 3px;
        background: #ffffff;
        border: 5px solid #ffffff;
      }

      .line {
        width: 100%;
        border-bottom: 1px dashed #1b4aa2;
        color: #1b4aa2;
        overflow: hidden;
        position: absolute;
        z-index: 0;
        top: 50%;
        line-height: 2px;
        transform: translate(0, -50%);
      }
    }

    h3 {
      width: 100%;
      margin: 20px 0 24px;
      font-weight: 700;
      font-size: 48px;
      line-height: 54px;
      letter-spacing: -0.02em;
      color: #225ccb;

      @include for-size(xs) {
        text-align: center;
      }
    }

    p {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
    }

    .infoWrapper {
      width: 100%;
      margin: 2rem 0 0;

      .info {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        gap: 10.68px;

        .shopType {
          text-transform: capitalize;
        }

        p {
          color: #1D2939;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .secure {
        width: fit-content;
        margin: 4px 0 0;
        padding: 10px 30px;
        background: #eff3f8;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7.18px;

        p {
          color: #8391A2;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }
  }

  .connectRight {
    position: relative;
    width: 50%;
    background: #eff3f8;
    border-radius: 0px 12px 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include for-size(sm) {
      padding: 20px 20px 120px;
      width: 100%;

      box-shadow: 2px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    .connectRightDefault {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 9.5rem;

      h5 {
        color: #1D2939;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        width: 71%;
      }

      p {
        width: 71%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #8391a2;
        margin: 40px 0 24px;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: #225CCB;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        gap: 8px;

        @include for-size(xs) {
          padding: 5px 20px;
          width: 100%;
        }

        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            width: fit-content;
            max-width: 90%;
            text-transform: initial;
            margin: 0;
          }
        }
      }
    }

    .paypalBig {
      width: 70%;
      height: 50%;
      margin-top: 19px;
      position: absolute;
      bottom: -74px;

      .skip {
        border-radius: 8px;
        background: #FCFCFC;
        padding: 10px 48px;
        position: absolute;
        bottom: 8rem;
        width: 100%;
        text-transform: capitalize;

        color: #225CCB;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}