.emailWrapper {
  box-sizing: border-box;
  width: 649px;
  height: 279.72px;
  background: #ffffff;
  border: 0.5px solid #eaeaea;
  box-shadow: 0px 1px 4px #00000012;
  border-radius: 12px;
  padding: 23.73px 26.95px 24.57px 27.22px;
  .heading {
    padding-bottom: 16.55px;
    border-bottom: 1px solid #d0d5dd;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }
  }
  .body {
    padding-top: 40.37px;
    .email {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30.28px;
      .emailLabel {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #8391a2;
        }
      }
      input {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 14px;
        gap: 8px;
        width: 326px;
        height: 44px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #101828;
      }
    }

    .buttonOptions {
      display: flex;
      justify-content: space-between;
      button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      .cancel {
        width: 79px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        color: #344054;
      }
      .update {
        width: 125px;
        height: 40px;
        background: #1b4aa2;
        border: 1px solid #1b4aa2;
        color: #ffffff;
      }
    }
  }
}
