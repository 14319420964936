@import '../../../size.mixin.scss';

.disputeUnderReview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 107.56px;

    .review {
        max-width: 406.73px;
        width: 100%;

        .getCoffee {
            text-align: center;

            img {
                max-width: 100%;
            }
        }
    }

    .statusStatement {

        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;

            margin: -13.82px auto 13.82px;
            max-width: 306px;
            text-align: center;
            color: #101828; //--gray--900

            @include for-size(xs) {
                font-size: 22px;
            }
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #344054; //--gray--700
        }
    }

    .reviewInfo {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;
        margin: 50.53px 1rem 36.43px;

        @include for-size(xs) {
            text-align: center;
        }

        .score {
            width: 63%;

            @include for-size(xs) {
                width: 100%;
            }

            .TrackiScore {
                display: flex;
                justify-content: space-between;

                font-size: 12px;
                line-height: 18px;

                .TrackiScoreTextWrapper {

                    .TrackiScoreText {
                        display: flex;

                        h5 {
                            font-style: normal;
                            font-size: 12px;
                            line-height: 18px;
                            font-weight: 600;
                        }

                        img {
                            margin-left: 6.83px;
                        }
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10.7018px;
                        line-height: 16px;
                        color: #8391A2; //--gray--500
                    }
                }


                .TrackiScorePercentage {
                    font-weight: 400;
                    color: #98A2B3;
                }
            }

            .linearDeterminateWrapper {
                margin-top: 12.18px;
            }

        }

        .timeEstimate {

            @include for-size(xs) {
                width: 100%;
            }

            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-align: center;

                padding: 2px 8px;
                background: #F4F8FF; //--primary--500
                color: #18408E; //--primary--700
                border-radius: 16px;
            }
        }

    }

    .viewSubmittedDispute {
        text-align: center;

        Button {
            text-transform: capitalize;
            border-radius: 8px;
        }
    }
}