@import '../../../size.mixin.scss';

.notificationsWrapper {
  .notificationsBody {
    background: #eef2f7;
    padding: 29px 57px;
    min-height: 100vh;

    @include for-size(xl) {
      padding: 32px 20px 60px;
    }

    @include for-size(lg) {
      padding: 32px 30px;
    }

    @include for-size(xs) {
      padding: 20px;
    }
  }

  .nav {
    display: flex;
    gap: 40px;
    margin-bottom: 20px;

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }

    .navPath {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 18px;

      p:nth-child(1) {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #8391a2;
      }

      p:nth-child(2) {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #18408e;
      }

      .cursor {
        cursor: pointer;
      }
    }
  }

  .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #344054;
    margin: 17px 0;
  }

  .notificationContainer {
    padding: 22px 0;
    border-radius: 11.808px;
    background: #FFF;
    box-shadow: 9.44641px 28.33923px 118.08012px 0px rgba(151, 128, 128, 0.10);

    .notificationTitle {
      padding: 10px 32px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 10.0182px;
        line-height: 15px;
        color: #8391a2;
      }
    }

    .notificationItem {
      padding: 10px 32px;

      .notification {
        padding: 8px 24px;
        transition: all 0.3s ease-in-out;
        display: flex;
        gap: 12px;
        position: relative;

        &:hover {
          background: #f4f5f9;
          border-left: 2px solid #225CCB;
        }

        .notifyIcon {
          border-radius: 60px;
          display: inline-flex;
          width: 28px;
          height: 28px;
          padding: 6px;
          align-items: flex-start;
          gap: 10px;
          flex-shrink: 0;
        }

        .notificationContent {
          gap: 4px;

          h3 {
            color: #475467;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 21.254px;
          }

          p {
            color: #475467;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 21.254px;
          }
        }
      }
    }
  }
}