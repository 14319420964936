.noCustomerInformation {
    font-size: 12px;
    color: #98A2B3;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.customerInformationWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10px;
    font-family: 'Inter', sans-serif;

    .orderFulFillmentContainer {
        margin-bottom: 10px;
    }
    .orderFulfillmentTitle {
        color: #1D2939;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    .orderFulfillmentDetails {
        color: #848484;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}