@mixin for-size($size) {
  @if $size == xxl {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $size == xl {
    @media (max-width: 1439px) and (min-width: 1201px) {
      @content;
    }
  } @else if $size == lg {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $size == mds {
    @media (max-width: 960px) {
      @content;
    }
  } @else if $size == sm {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $size == ms {
    @media (max-width: 596px) {
      @content;
    }
  } @else if $size == xs {
    @media (max-width: 425px) {
      @content;
    }
  } @else if $size == xxs {
    @media (max-width: 375px) {
      @content;
    }
  }
}
