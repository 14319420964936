@import '../../size.mixin.scss';

.progressWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  max-width: 500px;
  gap: 10px;
  position: relative;
  margin-bottom: 20px;

  @include for-size(xs) {
    width: 80%;
  }

  .progressChecks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
    font-size: 12.412px;
    line-height: 18px;
    text-align: center;
    color: #18408e;
    width: fit-content;
    position: relative;

    img {
      z-index: 1;
      border-radius: 50%;

      &:first-of-type {
        margin-left: -1px;
      }

      &:last-of-type {
        margin-right: -1px;
      }
    }

    p {
      position: absolute;
      top: 100%;
      white-space: nowrap;
    }
  }

  .half {
    width: 50%;
    position: absolute;
    border: 1px solid #18408e;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }

  .full {
    width: 100%;
    position: absolute;
    border: 1px solid #a7beea;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }

  .halfToFull{
    width: 100%;
    position: absolute;
    border: 1px solid #18408e;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
}
