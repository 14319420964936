@import '../../size.mixin.scss';

.registerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-top: 32px;

  .logo {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    align-items: center;
    top: 0;
    width: 100%;
    padding: 32px 42px 0;

    @include for-size(xs) {
      padding: 32px 20px 0;
    }

    .logoName {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-size: 20px;
        color: #fff;

        @include for-size(xs) {
          color: #08183a;
        }
      }

      p {
        font-size: 10px;
        color: #fff;

        @include for-size(xs) {
          color: #35a7ff;
        }
      }
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  .register {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-size(xs) {
      width: 100%;
      padding: 20px;
    }
  }

  .registerImage {
    width: 50%;
    height: 100%;
    background: #0e2551;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include for-size(xs) {
      display: none;
    }

    .registerCarousel {
      position: relative;
      width: 75%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      div[aria-label='Carousel Navigation'] {
        display: flex;
        width: 100%;
        height: 20px;
        list-style: none;
        position: absolute;
        bottom: 0;
        z-index: 99;
        transform: translate(0, -50%);
        transition: transform 0s;

        div[data-controls='next'],
        [data-controls='prev'] {
          position: absolute;
          color: #fff;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: fontSize 0s;
        }

        div[data-controls='next'] {
          right: 50px;
          transform: translate(45%, -40%);
        }

        div[data-controls='prev'] {
          left: 50px;
          transform: translate(-45%, -40%);
        }
      }

      div[aria-label='Carousel Pagination'] {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;

        div[data-nav] {
          align-self: center;
          justify-self: center;
          transform: translate(-45%, -10%);
          margin: 0 10px;
          font-size: 70px;
          color: #225ccb;

          img {
            height: 40px;
          }
        }

        div[class~='tns-nav-active'] {
          color: #fff;
        }
      }

      div[class='tns-outer'] {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        text-align: center;
        width: 95%;

        .reviewImage {
          margin: 16px 0;

          img {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        .reviewRating {
          color: #fec84b;
          margin: 16px 0;

          span {
            margin: 0 5px;
            font-size: 15px;
          }
        }

        .reviewReview {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 16px 0;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #ffffff;
        }

        .reviewName {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 20px;
          text-align: center;
          color: #fff;
        }

        .reviewOcc {
          margin: 4px 0 45px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          line-height: 20px;
          text-align: center;
          color: #a7beea;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 32px 42px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
    }
  }
}
