@import '../../size.mixin.scss';

.connectWrapper {
  width: 75vw;
  height: 637px;
  display: flex;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  max-width: 1000px;

  @include for-size(sm) {
    flex-direction: column;
    margin: auto;
    gap: 2rem;

    box-shadow: none;
  }

  .connectRight {
    position: relative;
    width: 50%;
    background: #eff3f8;
    border-radius: 0px 12px 12px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;

    @include for-size(sm) {
      padding: 20px 20px 40px;
      width: 100%;

      box-shadow: 2px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    p {
      width: 90%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #8391a2;
    }

    .paypalConnectedBox {
      width: 80%;

      .paypalEmail {
        width: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 20px 24px;

        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;

        margin-bottom: 30px;
        gap: 20px;

        @include for-size(xs) {
          padding: 5px 20px;
          width: 100%;
        }

        img {
          width: 26px;
          height: 30px;
          margin-right: 8px;
        }

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #101828;

          white-space: nowrap;
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .plus {
          background: #225ccb;
          color: #ffffff;
          border-radius: 3px;
          margin: 30px;
          width: 25px;
          height: 25px;
        }

        .delete {
          color: red;
          width: 16px;
          height: 16px;
        }
      }

      .continue {
        background: #225ccb;
        border: 1px solid #225ccb;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        text-transform: initial;
        color: #FFF;
        width: 90%;

        padding: 10px 18px;
      }

    }

    .paypalBig {
      position: absolute;
      bottom: 0;
    }
  }

  .connectLeft {
    position: relative;
    width: 50%;
    background: #ffffff;
    border-radius: 0px 12px 12px 0px;
    padding: 137.91px 50px;

    @include for-size(sm) {
      padding: 20px 20px 30px;
      width: 100%;

      box-shadow: 2px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    .lineConnect {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      color: #1b4aa2;

      * {
        z-index: 1;
      }

      img {
        width: 40px;
        height: 40px;
        padding: 5px;
        background: #ffffff;
        box-shadow: 0px 1.23452px 6.17261px rgba(16, 24, 40, 0.1),
          0px 1.23452px 12.3452px rgba(16, 24, 40, 0.06);
      }

      .lineCheck {
        background: #ffffff;
        box-shadow: none;
        border-radius: 0;
        width: 30px;
      }

      .lineCenter {
        margin: 0 3px;
        background: #ffffff;
        border: 5px solid #ffffff;
      }

      .line {
        width: 100%;
        border-bottom: 1px dashed #1b4aa2;
        color: #1b4aa2;
        overflow: hidden;
        position: absolute;
        z-index: 0;
        top: 50%;
        line-height: 2px;
        transform: translate(0, -50%);
      }
    }

    h3 {
      width: 100%;
      margin-top: 36px;
      font-weight: 700;
      font-size: 48px;
      line-height: 54px;
      letter-spacing: -0.02em;
      color: #225ccb;

      @include for-size(mds) {
        font-size: 38px;
      }

      @include for-size(xs) {
        text-align: center;
      }
    }

    .infoWrapper {
      width: 100%;
      margin: 2rem 0 0;

      .secure {
        width: fit-content;
        margin: 4px 0 0;
        padding: 10px 30px;
        background: #eff3f8;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7.18px;

        p {
          color: #8391A2;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }
  }
}