@import '../../../size.mixin.scss';

.disputeResolverWrapper {
    padding: 29.41px 23.3px 25.6px;

    .disputeResolverAI {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 22.85px;
        gap: 10.23px;
        border-bottom: 1px solid #F2F4F7;

        img {
            margin-top: 5px;
        }

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 2px;

            color: #0E2551; //--primary--900
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            color: #344054; //--gray--700
        }
    }

    .disputeResolverAIBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 26.78px;

        .disputeResolverDisc {
            h3 {
                color: #1D2939;
                // text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                margin: 8px 0 12px;
            }

            .reviewInfo {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 24px;

                @include for-size(xs) {
                    text-align: center;
                }

                .score {
                    width: 63%;

                    @include for-size(xs) {
                        width: 100%;
                    }

                    .TrackiScore {
                        display: flex;
                        justify-content: flex-end;

                        font-size: 12px;
                        line-height: 18px;

                        .TrackiScorePercentage {
                            font-weight: 400;
                            color: #98A2B3;
                        }
                    }

                    .linearDeterminateWrapper {
                        margin-top: 12.18px;
                    }
                }

                .timeEstimate {

                    @include for-size(xs) {
                        width: 100%;
                    }

                    h5 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;

                        padding: 2px 8px;
                        background: #F4F8FF; //--primary--500
                        color: #18408E; //--primary--700
                        border-radius: 16px;
                    }
                }
            }
        }
    }
}