@import "../../size.mixin.scss";

//AddAccount-css
.addAccountModal {

  .addAccountHeading {
    color: #101828;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  .subHeading {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8391a2;
    margin-top: 7px;
  }

  .institutions {
    margin-top: 24px;
  }

  .institutionsTitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #98a2b3;
    margin: 24px 0;
  }

  .institutionsList {
    .institutionWrapper:last-child {
      border-bottom: none;
    }

    .institutionWrapper:hover {
      background: #E5E7F6;
      cursor: pointer;
      // padding: 12px 24px;
      // border-radius: 8px;
      // border-bottom: none;
    }

    .institutionWrapper {
      padding: 16px 0 12px;
      border-bottom: 1px solid #CDD5E4;

      .hoverInstitution {
        display: flex;
        justify-content: space-between;

        .institution {
          display: flex;
          gap: 12px;
          margin-top: 12px;


          .institutionLogo {
            width: 22px;
            height: 25.96px;
            object-fit: cover;
            margin-top: 5px;
          }

          .lineGroup {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid #eaecf0;

            .institutionNameHolder {
              display: flex;
              gap: 12px;

              .betaIcon{
                height: 22px;
                width: 60px;
                padding: 2px 8px 2px 8px;
                border-radius: 18px;
                border: 2px solid  #FFB018;
                font-size: 10px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #FFB018;

                span{
                  width: 10px;
                  height: 10px;
                  background: #FFB018;
                  display: inline-block;
                  // margin-right: 10px;
                  border-radius: 50%;
                }

              }

              .institutionName {
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
                color: #1d2939;
              }

              .badgeHolder {
                padding: 2px 8px;
                border-radius: 16px;
                background: #F4F8FF;

                .badge {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  p {
                    color: #18408E;
                    text-align: center;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                  }
                }
              }
            }

            .institutionLink {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: #8391a2;
            }
          }
        }

        img {
          @include for-size(ms) {
            display: none;
          }
        }
      }
    }
  }
}

//RemoveAccount-scss
.removeAccountModal {
  padding: 26px 27px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
    border-bottom: 1px solid #d0d5dd;
    padding-bottom: 12px;
  }

  .info {
    padding: 14.33px 0 12.35px;
    border-bottom: 1px solid #d0d5dd;

    .detailsWrap {
      display: flex;
      gap: 20px;
      margin-top: 15px;

      .detail {
        display: flex;
        gap: 6px;

        .imgWrap {
          width: 20.94px;
          height: 20.94px;
          background: #e9effa;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 10px;
            height: 10px;
            object-fit: cover;
          }
        }

        .name {
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #1d2939;
        }

        .email {
          font-weight: 500;
          font-size: 11px;
          line-height: 18px;
          color: #8391a2;
        }

        .id {
          font-weight: 600;
          font-size: 9px;
          line-height: 18px;
          color: #8391a2;
        }
      }
    }
  }

  .confirm {
    margin-top: 20px;
    display: flex;
    gap: 34px;

    p {
      width: 50%;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }

    input {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      background: transparent;
      padding: 10px 14px;
      width: 50%;

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #98a2b3;
      }
    }
  }

  .buttons {
    margin-top: 43.63px;
    display: flex;
    justify-content: space-between;

    button {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 10px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .cancel {
      background: transparent;
      color: #344054;

      &:hover {
        background: #fafafa;
      }
    }

    .remove {
      background: #f04438;
      color: #ffffff;
      opacity: 0.3;
      cursor: not-allowed;

      &.active {
        cursor: pointer;
        opacity: 1;

        &:hover {
          background: #f04438;
          filter: brightness(0.8);
        }
      }
    }
  }
}

.popmodal {
  @include for-size(xl) {
    width: 80%;
  }

  @include for-size(lg) {
    width: 80%;
  }
}


//activate-chargePay
.modal {
  width: 28%;
  background: #FFF;
}

.activateAccountModal {
  padding: 32px;

  .activateAccountWrapper {

    .activateAccountHeader {

      h4 {
        color: #101828;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 12px;
      }

      p {
        color: #8391A2;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .activateAccountBody {
      margin-top: 24px;

      h6 {
        color: #101828;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21.254px;
      }

      .activateAccountExpectWrapper {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .activateAccountExpect {
          font-style: normal;
          font-weight: 500;
          line-height: 150%;

          .activateExpectTitle {
            border-radius: 60px;
            background: #EFF0F7;
            padding: 2px 8px;
            margin-bottom: 4px;
            width: fit-content;

            p {
              color: #225CCB;
              font-size: 14px;
            }
          }

          p {
            color: #101828;
            font-size: 16px;

            span {
              font-weight: bold;
            }
          }
        }
      }
    }

    .activateAccountFooter {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      button {
        width: 100%;
        text-transform: capitalize;
        font-size: 14px;
      }

      .skip {
        color: #8391A2;
        font-size: 16px;
      }
    }
  }
}


//Disputes-risk-scss
.riskModal {
  padding: 32px;

  .riskWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .riskHeader {
      display: flex;
      align-items: center;
      gap: 12px;

      h4 {
        color: #101828;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .riskBody {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .riskExpect {
        color: #101828;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .riskFooter {
      display: flex;
      gap: 12px;

      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      button {
        text-transform: initial;
      }

      .riskUnderstand {
        color: #8391A2;
        font-size: 16px;
      }
    }
  }
}