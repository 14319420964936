@import '../../size.mixin.scss';

.notificationsWrapper {
  width: 295.53px;
  height: 377.17px;
  background: #ffffff;
  box-shadow: 8px 24px 100px rgba(151, 128, 128, 0.1);
  border-radius: 4.17426px;
  z-index: 1;

  @include for-size(xs) {
    left: 60px;
    top: 60px;
    width: calc(100vw - 70px);
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54.67px;
    padding: 0 22.16px;

    p {
      color: #225CCB;
      font-size: 17.712px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.254px;
    }

    button {
      font-weight: 500;
      font-size: 10.0182px;
      line-height: 15px;
      color: #1643c8;
    }
  }

  .options {
    .option {
      position: relative;
      display: flex;
      align-items: flex-start;
      height: 68.55px;
      gap: 12.19px;
      padding: 0 22.16px;

      .notifyIcon {
        border-radius: 60px;
        display: inline-flex;
        width: 28px;
        height: 28px;
        padding: 6px;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
      }

      &:active,
      &:focus,
      &:hover {
        background: #f4f5f9;
      }

      p {
        font-weight: 600;
        font-size: 13.3576px;
        line-height: 14px;
        color: #1d2939;
      }

      span {
        font-weight: 500;
        font-size: 10.0182px;
        line-height: 15px;
        color: #8391a2;
      }
    }
  }
}