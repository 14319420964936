

.NoData {
    margin: 2rem auto 5rem;
    text-align: center;

    h3 {
        margin: 2rem;
        color: #8391a2;
    }
}