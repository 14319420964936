.paddleWrapper {
  // height: 100vh;
  background: #eff3f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 100vh;
  width: 100%;
  padding: 48px 0;
  gap: 36px;

  .bottomPaddle{
    margin-top: 42px;
  }

  .progressWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 9.66%;
    width: 575px;

    .progressChecks {
      margin-bottom: 50px;
      color: #225ccb;
      position: relative;
      font-size: 14px;

      div {
        display: flex;
        flex-direction: column;
      }

      .check {
        ::before {
          content: "";
          display: block;
          width: 242px;
          height: 1px;
          background: #225ccb;
          left: 64px;
          top: 18%;
          position: absolute;
        }

        ::after {
          content: "";
          width: 243px;
          height: 1px;
          background: #225ccb;
          right: 64px;
          top: 18%;
          position: absolute;
        }
      }
      .lastCheck {
        color: #abc9ed;
      }
    }
  }
}
