@import '../../size.mixin.scss';

.loginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .login {
    height: 38px;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #101828;
  }

  .welcome {
    height: 24px;
    margin-top: 12px;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8391a2;
  }

  label {
    height: 20px;
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }

  .input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 44px;

    .domain {
      width: 100%;
    }
  }

  .email {
    margin-bottom: 20px;
  }

  .shopify {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
  }

  .account {
    text-align: center;
    margin-top: 16px;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8391a2;

    a {
      color: #18408e;
      text-decoration: none;
    }
  }
}