.fraudAnalysisWrapper {
    display: flex;
    align-items: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-left: 5.27px;

        color: #98A2B3;
    }
}