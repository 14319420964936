.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  overflow: scroll;
  background: rgba(4, 16, 38, 0.48);
  z-index: 99;
  display: none;

  &.isOpen {
    display: block;
  }

  .popup {
    background: #eff0f7;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    margin-top: 10px;

    .close {
      width: 40px;
      height: 40px;
      background: #1e2b47;
      border-radius: 50%;
      position: absolute;
      top: -35px;
      right: -35px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 13px;
        height: 13px;
      }
    }
  }
}