.premiumWrapper {
  padding: 37.27px 42.92px 37.27px 39.72px;

  .heading {
    display: flex;
    justify-content: space-between;

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }

    button {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #225ccb;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 14px;
      gap: 8px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }
  .intro {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8391a2;
    margin: 21.32px 0;
  }
  .cardsWrapper {
    display: flex;
    gap: 23px;

    .trialCards {
      box-sizing: border-box;
      position: relative;
      width: 313px;
      padding-bottom: 143px;
      background: #ffffff;
      border: 0.704861px solid #eaecf0;
      box-shadow: 0px 8.45833px 11.2778px -2.81944px rgba(16, 24, 40, 0.08),
        0px 2.81944px 4.22917px -1.40972px rgba(16, 24, 40, 0.03);
      border-radius: 11.2778px;

      .cardTop {
        padding: 35px;
        position: relative;

        .recommended {
          position: absolute;
          top: 9px;
          right: 12px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 2px 8px 2px 6px;
          gap: 4px;
          width: 117px;
          height: 22px;
          background: #f4f8ff;
          border-radius: 16px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #18408e;
        }
        h3 {
          font-family: 'Inter';
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #1d2939;
          display: flex;
          align-items: center;
          gap: 3.1px;
          .icon {
            color: goldenrod;
          }
        }

        p {
          width: 254px;
          height: 20px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: flex-end;
          color: #8391a2;
        }

        ul {
          margin: 9px 9px 30px 0;

          li {
            list-style: none;
            margin-top: 9px;
            width: 250px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #344054;

            span {
              display: inline-block;
              margin-right: 10px;
              color: #ff9100;
              border-radius: 50%;
            }
          }

          .premium {
            font-weight: 600;
            color: #225ccb;
          }
        }
      }

      .buttonWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 22.5556px;
        gap: 14px;
        position: absolute;
        width: 100%;
        height: 143px;
        bottom: 0;
        background: #f2f8ff;

        button {
          box-shadow: 0px 0.659286px 1.31857px rgba(16, 24, 40, 0.05);
          border-radius: 5.27429px;
          font-weight: 600;
          font-size: 14.9655px;
          line-height: 22px;
          color: #ffffff;
          width: 250.98px;
          height: 51.44px;
        }
        .buttonCurrent {
          background: #98a2b3;
          border: 0.659286px solid #98a2b3;
        }
        .buttonTrial {
          background: #225ccb;
          border: 0.659286px solid #225ccb;
        }
        div {
          width: 267.89px;
          font-weight: 400;
          font-size: 11.2241px;
          line-height: 17px;
          text-align: center;
          color: #101828;
          text-align: center;
          display: flex;
          justify-content: space-around;
          color: #101828;
        }
      }
    }
  }
}
