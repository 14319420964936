.orderFulfillmentContainer{
    border-bottom: 1px solid #E9EFFA;

    .orderFulfillmentWrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 18px;
        font-family: 'Inter', sans-serif;

        .orderFulfillmentTitle{
            color: #1D2939;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 150% */
        }
        .orderFulfillmentDetails{
            color: #1D2939;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            margin-top: 0px;
        }
        .orderFulfillmentDetailsSuccess {
            background: #ECFDF3;
            color: #027A48;
            font-size: 10.56px;
            font-style: normal;
            font-weight: 500;
            line-height: 15.85px;
            padding: 4px 8px;
            border-radius: 20px;
            font-weight: bold;
        }

        .orderFulfillmentDetailsError {
            background: #ECFDF3;
            color: #b0382d;
            font-size: 10.56px;
            font-style: normal;
            font-weight: 500;
            line-height: 15.85px;
            padding: 4px 8px;
            border-radius: 20px;
            font-weight: bold;
        }

    }


}