@import '../../../size.mixin.scss';

.tableWrapper {
    box-sizing: border-box;
    min-width: 100%;
    min-height: 100%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    overflow-x: auto;

    table {
        margin: 10px 30px 0;
        width: calc(100% - 60px);
        border-collapse: collapse;

        th,
        td {
            padding: 15px 10px;
            min-width: fit-content;
        }

        tr {
            border-bottom: 1px solid #eff0f7;
        }

        th {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #8391a2;
            text-align: left;
        }

        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #1D2939;
        }

        .disputeWrapper {
            .id {
                color: #225CCB; //--primary--500
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;

                // max-width: 5rem;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .store {
                display: flex;
                align-items: center;

                img {
                    width: 20.01px;
                    height: 18px;
                }

                .storeText {
                    font-weight: 500;
                    line-height: 18px;
                    font-style: normal;
                    padding: 10px 10px;
                    min-width: fit-content;
                    margin-top: 15px;

                    .storeName {
                        font-size: 13px;
                        // max-width: 6rem;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .storeEmail {
                        font-size: 11px;
                        color: #8391A2;

                        max-width: 6rem;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            .status {
                display: flex;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                width: fit-content;

                p {
                    text-transform: lowercase;
                    font-size: 12px;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                p:first-letter {
                    text-transform: capitalize;
                }
            }

            .carrier {

                p {
                    font-weight: 500;
                    line-height: 18px;
                }

                .carrierTextId {
                    font-size: 10px;
                    color: #225CCB;
                }
            }

            .reasonType {
                text-align: center;
                border-radius: 4px;
                width: max-content;
                padding: 4px 8px;

                // max-width: 7rem;
                background: #EFF0F7;


                p {
                    text-transform: lowercase;
                    font-size: 12px;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                p:first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }
}