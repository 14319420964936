.orderStatusWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 64px;
    padding: 0 18px;
    border: 1px solid #D1DAEE;
    font-family: 'Inter', sans-serif;
    overflow: hidden;

    .orderStatus {
        margin-left: 10px;
        color: #000;
        font-size: 14px;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}