.linkedAccWrapper {
  padding: 38.59px 32.29px 54.73px 39.79px;

  .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
    margin-bottom: 21.32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    .intro {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
      margin-bottom: 25.56px;
    }

    button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 14px;
      gap: 8px;
      background: #1b4aa2;
      border: 1px solid #1b4aa2;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }

  .ecommerce {
    box-sizing: border-box;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    margin-bottom: 24.84px;
    padding: 25px 26px 30px;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      gap: 12.55px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #101828;
      margin-bottom: 9px;

      .icon {
        width: 15.87px;
        height: 10.3px;
        color: #225ccb;
      }
    }

    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #8391a2;
      margin-bottom: 15px;
    }

    .details {
      background: #eff0f7;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 15px 24.8px 13px 11.97px;

      .detail:nth-child(1) {
        border-bottom: 1px solid #d0d5dd;
      }

      .detail {
        padding: 15px 0 13px;

        .linkeEcommerce {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .emailWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15.03px;

            .emailInfo {
              display: flex;
              flex-direction: column;

              .email {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;

                color: #1D2939;
              }

              .ecommerceIcon {
                display: flex;
                align-items: center;

                img {
                  width: 12.814px;
                }

                p {
                  font-size: 11px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px;
                  color: #8391A2;
                }
              }
            }
          }

          .actions {
            button {
              text-transform: capitalize;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5.1713px 9.04978px;
              gap: 5.17px;
              background: #FFF;
              border: 0.646413px solid #d0d5dd;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 5.1713px;

              img {
                width: 12.928px;
              }

              p {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                color: #344054;
              }
            }
          }

        }
      }
    }
  }


  .info {
    box-sizing: border-box;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    padding: 25px 26px 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 67.2px;

    .title {
      display: flex;
      gap: 12.55px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #101828;
      margin-bottom: 9px;

      .icon {
        width: 15.87px;
        height: 10.3px;
        color: #225ccb;
      }
    }

    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #8391a2;
      margin-bottom: 15px;
    }

    .details {
      background: #eff0f7;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0px 24.8px 0px 11.97px;

      .detail:not(:last-child) {
        border-bottom: 1px solid #d0d5dd;
      }

      .detail {
        padding: 15.79px 0 12.12px;

        .paypalWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;


          .emailWrapper {
            display: flex;
            align-items: center;
            gap: 12.18px;

            .emailInfo {
              display: flex;
              justify-content: center;
              flex-direction: column;
              gap: 3px;

              .email {
                font-weight: 500;
                font-size: 13px;
                line-height: 18px;
                color: #1d2939;
              }

              .paypalId {
                color: #8391A2;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
            }
          }

          .actions {
            display: flex;
            gap: 8.78px;

            button {
              text-transform: capitalize;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5.1713px 9.04978px;
              gap: 5.17px;
              background: #FFF;
              border: 0.646413px solid #d0d5dd;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 5.1713px;

              img {
                width: 12.928px;
              }

              p {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                color: #344054;
              }
            }
          }
        }
      }
    }
  }
}