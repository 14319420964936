.disputeWonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 48.45px 0 184.16px;

    .disputeWon {
        max-width: 412px;
        width: 95%;

        .underWinner {
            text-align: center;

            img {
                max-width: 100%;
            }
        }

    }

    .statusStatement {
        margin-top: 38.69px;

        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;

            margin: 0 auto;

            max-width: 233.03px;
            text-align: center;
            color: #101828; //--gray--900
        }

        h3 {
            padding: 4px 12px;
            border-radius: 16px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            text-align: center;

            margin-top: 11.31px;

            color: #12B76A; //--success--500
            background: #ECFDF3; //--success--50
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            margin-top: 16px;

            text-align: center;
            color: #1D2939; //--gray--800
        }
    }


    .viewSubmittedDispute {
        margin-top: 2rem;
        text-align: center;

        Button {
            text-transform: capitalize;
            border-radius: 8px;
        }
    }
}