@import '../../../size.mixin.scss';

.stageWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 12px;

    @include for-size(ms) {
        flex-direction: column;
    }

    .stage_1 {
        width: 58%;

        background: #FFFFFF;
        border: 0.5px solid #EAEAEA;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        padding: 21px 17px 25.59px;

        @include for-size(ms) {
            width: 100%;
        }
    }

    .stage_2,
    .stage_3 {
        width: 30%;

        background: #FFFFFF;
        border: 0.5px solid #EAEAEA;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
        border-radius: 12px;

        @include for-size(ms) {
            width: 100%;
        }
    }

    .underReview {
        width: inherit;

        background: #FFFFFF;
        border: 0.5px solid #EAEAEA;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        padding: 21px 17px 25.59px;

        @include for-size(ms) {
            width: 100%;
        }
    }
}