@import '../../size.mixin.scss';

.sidebarWrapper {
  background: #ffffff;
  width: 246px;
  height: 100vh;
  padding: 0 29px;
  z-index: 2;

  @include for-size(lg) {
    width: 60px;

    &:hover {
      width: 246px;
      position: fixed;
      left: 0;
    }
  }

  @include for-size(xxs) {
    width: 50px;
  }

  .sidebar {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;

    width: 246px;
    padding: 0 29px;

    transition: width 0.2s ease;

    @include for-size(lg) {

      width: 60px;
      padding: 0 5px;

      &:hover {
        width: 246px;
        height: 100vh;
        padding: 0 29px;

        button {
          padding: 6px 8px;
        }

        .logo {
          .logoName {
            display: flex;
          }
        }

        .intro {
          display: flex;
          justify-content: space-between;
          gap: 33px;

          .heading {
            display: flex;
            flex: none;
          }

          .headingIcons {
            flex-direction: row;
          }
        }

        .sidebarList {
          .listItem {
            justify-content: space-between;

            .down {
              display: block;
            }
          }

          .downSidebar {
            margin-top: 4rem;

            p {
              display: block;
            }
          }

          .list {
            justify-content: flex-start;

            p {
              display: block;
            }
          }
        }

        // .sidebarFooter {
        //   display: block;
        // }
      }
    }

    button {
      border-radius: 2rem;

      @include for-size(lg) {
        padding: 0px;
      }
    }

    .logo {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding: 32px 0;
      align-items: center;

      .logoName {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(lg) {
          display: none;
        }

        h3 {
          font-size: 20px;
          color: #08183a;
        }

        p {
          font-size: 10px;
          color: #35a7ff;
        }
      }
    }

    .intro {
      display: flex;
      justify-content: space-between;
      margin-bottom: 29px;

      .heading {
        display: flex;
        flex-direction: column;
        text-transform: capitalize;

        @include for-size(lg) {
          display: none;
        }

        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          color: #101828;
        }

        .selfService {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          gap: 5px;
          color: #a6b2c1;
          width: 100%;
        }
      }

      .headingIcons {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;

        flex-direction: row;

        @include for-size(lg) {
          flex-direction: column;
          width: 100%;
        }

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }

    .sidebarList {
      display: flex;
      flex-direction: column;
      gap: 20.22px;

      position: relative;

      .downSidebar {
        margin-top: 220.23px;

        p {
          color: #8391A2;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 21.146px;
          text-transform: uppercase;

          @include for-size(lg) {
            display: none;
          }
        }
      }

      .listItem {
        display: flex;
        align-items: center;
        cursor: pointer;

        justify-content: space-between;

        @include for-size(lg) {
          justify-content: center;
        }

        .down {
          transition: all 0.3s ease-in-out;

          display: block;

          @include for-size(lg) {
            display: none;
          }

          &.active {
            transform: rotate(180deg);
          }
        }
      }

      .borderTop {
        border-top: 1px solid #DFDFDF;
        padding-top: 16px;
      }

      .list {
        display: flex;
        align-items: center;
        gap: 14.55px;

        justify-content: flex-start;

        @include for-size(lg) {
          justify-content: center;
        }

        .active {
          color: #225ccb;
        }

        .icon {
          width: 27.77px;
          height: 27.77px;
          background: #e9effa;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 17px;
          color: #1d2939;

          display: block;

          @include for-size(lg) {
            display: none;
          }
        }

        .logout {
          color: #667085;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 17px;
          display: block;

          @include for-size(lg) {
            display: none;
          }
        }
      }
    }

    // .sidebarFooter {
    //   margin-top: 25.22px;

    //   h3 {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 15px;
    //     line-height: 24px;
    //     color: #1d2939; //--gray--800
    //   }

    //   p {
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 15px;
    //     line-height: 24px;
    //     color: #8391a2; //--gray--500
    //     margin-bottom: 12.26px;
    //   }

    //   @include for-size(lg) {
    //     display: none;
    //   }
    // }
  }
}