@import '../../../size.mixin.scss';

.disputesWrapper {
  background: #e8e8e8;
  width: 100%;
  height: 100%;
  padding: 22.68px 48px 55px 55px;

  @include for-size(lg) {
    padding: 32px 30px;
    gap: 10px;
  }

  @include for-size(xs) {
    padding: 20px;
  }

  .nav {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 21.99px;

    @include for-size(xs) {
      gap: 10px;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }

    .navPath {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 18px;

      div:nth-child(1)>p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #8391a2;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #18408e;
      }

      .cursor {
        cursor: pointer;
      }
    }
  }

  .note {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #344054;
    }
  }

  .status {
    display: flex;
    flex-wrap: wrap;
    gap: 14.82px;
    margin-top: 26px;

    .statusDiv {
      padding: 12.74px 20.49px;
      background: #ffffff;
      border-radius: 100px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #101828;
      display: flex;
      align-items: center;
      gap: 4.53px;
      text-transform: capitalize;

      .open {
        width: 7.75px;
        height: 7.75px;
        border-radius: 50%;
      }
    }

    .allDisputes {
      background: #101828;
      border-radius: 100px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }

  .search {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    margin: 22px 0 16px;
    padding: 0 32px;

    .searchTop {
      width: 100%;
      padding: 29.02px 0 35.31px;
      background: #ffffff;
      display: flex;
      align-items: center;
      gap: 23px;

      @include for-size(xs) {
        flex-wrap: wrap;
        gap: 10px;
      }

      @include for-size(md) {
        gap: 17px;
      }

      @include for-size(sm) {
        flex-direction: column;
      }

      .searchDiv {
        width: 319px;
        min-width: fit-content;

        @include for-size(md) {
          width: 30%;
        }

        @include for-size(sm) {
          width: 100%;
        }

        .searchInput {
          display: flex;
        }
      }

      .date {
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 14px;
        gap: 8px;
        width: 20%;
        height: 44px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101828;

        .icon {
          width: 15px;
          height: 15px;
          color: #8391a2;
        }

        .datePicker {
          cursor: pointer;
        }

        @include for-size(lg) {
          width: 20%;
        }

        @include for-size(md) {
          width: 30%;
          font-size: 10px;
          font-weight: 600;
        }

        @include for-size(sm) {
          width: 100%;
          gap: 10px;
        }
      }

      .filterButton {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        width: 114.33px;
        background: #1b4aa2;
        border: 1px solid #1b4aa2;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;

        @include for-size(md) {
          width: 30%;
          gap: 10px;
        }

        @include for-size(sm) {
          width: 100%;
          gap: 10px;
        }

        @include for-size(xs) {
          flex-wrap: wrap;
        }

        .icon {
          width: 19.44px;
          height: 17.5px;

          @include for-size(md) {
            width: 16px;
          }
        }
      }
    }

    .filters {
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      padding-bottom: 46px;
    }
  }
}