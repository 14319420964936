.paypalWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 48px 0;
  gap: 36px;
  background: #eff3f8;
}

