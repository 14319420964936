.underReviewWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18.95px 26px;
    height: 100%;

    div {
        text-align: center;

        .postReviewInfo {
            margin-top: 16.5px;

            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;

                color: #344054; //--Gray--700
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;

                color: #98A2B3; //--Gray--400
            }
        }

    }
}