.orderWrapper {
    display: flex;
    justify-content: space-between;
    padding: 13.54px 0 15.14px;
    border-bottom: 1px solid #F2F4F7;
    gap: 7px;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .orderName {
        width: 119px;
    }
}

.orderCalcWrapper {
    padding: 15.25px 0 19.63px;

    .orderCalc {
        display: flex;
        justify-content: space-between;
        margin-top: 6.82px;

        .CalcText {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
        }

        .discountCode {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            color: #98A2B3; //--Gray--400
        }

        .totalAmount {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
        }
    }
}