.braintreeWrapper {
    margin-bottom: 1rem;

    h3 {
        color: #101828;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

    .braintreeDescri {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        color: #101828;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .braintreeSteps {
            padding-left: 24px;

            li {
                span {
                    font-weight: bold;
                }
            }
        }

        .form {
            display: flex;
            flex-direction: column;
            gap: 12px;

            color: #344054;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            .returnShippingAddress {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;

                input {
                    width: 100%;
                    padding: 10px 14px;
                    border-radius: 8px;
                    border: 1px solid #D0D5DD;
                    background: #FFF;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }

                .error {
                    color: #bf1650;
                    font-size: small;
                }
            }
        }

        button {
            margin-top: 24px;
            padding: 8px 14px;
            text-transform: capitalize;
            background: #1B4AA2;
            border-radius: 8px;
        }
    }
}