.accordianWrapper {
    margin: 0 !important;

    .accordianSummary {
        background: #F9F9FB !important;

        .accordianTitle {
            display: flex;
            align-items: center;

            img {
                width: 20.89px;
            }

            p {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                margin-left: 7.82px;
                font-family: 'Inter', sans-serif;
            }
        }

    }
    .accordianDetails{
        padding: 0;
    }
}