@import '../../size.mixin.scss';

.wrapper {
  padding: 20px 29.17px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-size(xxs) {
    padding: 20px 10px;
  }

  .right,
  .left {
    display: flex;
    gap: 12px;
    cursor: pointer;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
      transition: all 0.3s ease-in-out;

      @include for-size(sm) {
        display: none;
      }

      &:hover {
        color: #1b4aa2;
      }
    }

    &.right {
      .icon {
        transform: rotate(180deg);
      }
    }

    &.unavailable {
      cursor: not-allowed;

      p {
        &:hover {
          color: #8391a2;
        }
      }
    }
  }

  .pages {
    display: flex;
    gap: 5px;

    .page {
      height: 40px;
      min-width: 35px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      transition: all 0.3s ease-in-out;
      color: #8391a2;

      @include for-size(sm) {
        height: 25px;
        width: 25px;
      }

      @include for-size(xxs) {
        height: 20px;
        width: 20px;
      }

      &.active {
        background: #f4f8ff;
        border-radius: 8px;
        color: #1b4aa2;

        @include for-size(sm) {
          width: 25px;
        }

        @include for-size(xxs) {
          height: 20px;
          width: 20px;
        }
      }

      &.unavailable {
        cursor: not-allowed;
      }

      &:hover {
        background: #1b4aa2;
        border-radius: 8px;
        color: #fff;

        @include for-size(sm) {
          width: 25px;
        }

        &.unavailable {
          background: transparent;
          color: #8391a2;
          width: 35px;
        }
      }
    }
  }
}
