.optionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 724px;
  height: 626px;
  background: #0e1414;
  border-radius: 20px;

  .purchaseCard {
    width: 429px;
    height: 545px;
    background: white;
  }
}
