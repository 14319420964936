@import '../../../size.mixin.scss';

.recentOrderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @include for-size(md) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
    }


    .recentOrderHeader_sub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @include for-size(md) {
            width: 100%;
        }

        .recentOrderHeading {
            margin-right: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #101828;
        }


        .recentOrderHeader_Btn {

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #225CCB;
                border-radius: 16px;
                padding: 2px 8px;

                img {
                    margin-right: 4.5px;
                }

                p {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #FFFFFF;
                }

            }
        }
    }

    .recentOrderHeader_description {
        font-weight: 500;
        font-size: 12px;
        color: #8391A2;
    }
}