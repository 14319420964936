@import '../../size.mixin.scss';

.spinner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 15%;
        height: 15%;
    }
}

