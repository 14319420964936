@import '../../../size.mixin.scss';

.tableWrapper {
    box-sizing: border-box;
    max-width: 100%;
    min-height: 100%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;


    @include for-size(lg) {
        overflow-x: scroll;
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 230px;
    }

    table {
        margin: 10px 30px;
        width: calc(100% - 60px);
        border-collapse: collapse;

        th,
        td {
            padding: 15px 10px;
            min-width: fit-content;
        }

        th {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #8391a2;
            text-align: left;

            img {
                margin-left: 4px;
            }
        }

        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #1D2939;
        }

        .tableDataRow {
            border-top: 1px solid #eff0f7;
        }

        .recentDisputeID {
            color: #225CCB; //--primary--500
            text-decoration: underline;
            cursor: pointer;
        }

        .store {
            display: flex;
            align-items: center;

            img {
                width: 20.01px;
                height: 18px;
            }

            .storeText {
                font-weight: 500;
                line-height: 18px;
                font-style: normal;
                padding: 10px 10px;
                min-width: fit-content;

                .storeName {
                    font-size: 13px;
                    // max-width: 6rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .storeEmail {
                    font-size: 11px;
                    color: #8391A2;

                    max-width: 6rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

        .recentDisputeReason {
            padding: 4px 8px;
            background: #EFF0F7;
            width: fit-content;

            p {
                text-transform: lowercase;
            }

            p:first-letter {
                text-transform: capitalize;
            }
        }

        .recentDisputeStatus {
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            width: fit-content;

            p {
                text-transform: lowercase;
            }

            p:first-letter {
                text-transform: capitalize;
            }
        }

        .completed {
            gap: 6px;
            display: inline-block;
            padding: 5px 10px;
            white-space: nowrap;
        }

        .transit {
            gap: 5.5px;
            display: inline-block;
            padding: 5px 10px;
            white-space: nowrap;
        }

        .overdue,
        .completed,
        .transit {
            display: inline-block;
            padding: 3px 10px;
            white-space: nowrap;
            border-radius: 1000px;

            &.completed {
                background: #ECFDF3;
                color: #027a48;
            }

            &.overdue {
                background: #FEF0C7;
                color: #DC6803;
            }

            &.transit {
                background: #EFF8FF;
                color: #175CD3;
            }
        }
    }
}