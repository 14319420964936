.disputeUnderReviewWrapper {
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    .disputeUnderReviewData {
        display: flex;
        justify-content: space-between;
        margin-top: 23.48px;

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 9.5px;

            color: #344054; //--Gray--700
        }

        .disputeAwaitingStatus,
        .disputeSubmitStatus {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            display: inline-block;
            text-align: center;
            border-radius: 16px;
            padding: 4px 12px;

            &.disputeAwaitingStatus {
                background: #F0F9FF; //--blue-light--50
                color: #026AA2; //--blue-light--700
            }

            &.disputeSubmitStatus {
                background: #ECFDF3; //--Success--50
                color: #027A48; //--Success--700
            }
        }
    }
}