@import '../../size.mixin.scss';

.disputesWrapper {
    background: #EFF0F7;
    width: 100%;
    height: 100vh;
    padding: 22.68px 54px 55px 56px;

    @include for-size(xl) {
        padding: 32px 20px 60px;
    }

    @include for-size(lg) {
        padding: 32px 30px;
    }

    @include for-size(sm) {
        height: max-content;
    }

    @include for-size(xs) {
        padding: 20px;
    }

    .nav {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-bottom: 21.99px;

        @include for-size(xs) {
            gap: 10px;
        }

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #101828;
        }

        .navPath {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 18px;

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #8391a2;
            }

            p:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #18408e;
            }

            .cursor {
                cursor: pointer;
            }
        }
    }

    .settingsWrapper {
        max-width: 793px;
        width: 100%;

        .settingsTabsWrapper {
            border-radius: 12px;
            border: 0.5px solid #EAEAEA;
            background: #FFF;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);
            padding: 0 21px 0;

            .settingsTabs {
                text-transform: capitalize;
            }
        }

        .settingsPanelContainer {
            margin-top: 18px;

            .settings {
                border-radius: 12px;
                border: 0.5px solid #EAEAEA;
                background: #FFF;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);
            }
        }
    }
}