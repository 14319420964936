.transactionInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 9px 0;
    border: 1px solid #F2F4F7;
    gap: 10px;

    .transactionData {
        width: 40%;

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
        }

        .transactionValue {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            margin-top: 6.65px;
            color: #98A2B3; //--Gray--400
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.evidenceWrapper {
    height: 94.21px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        color: #98A2B3; //--Gray--400
    }
}