@import "../../../size.mixin.scss";

.profileWrapper {
  padding: 38.59px 32.29px 54.73px 39.79px;

  h3 {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #475467;
    margin-bottom: 11.95px;
  }

  .details {
    box-sizing: border-box;
    width: 80%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    margin-bottom: 26.06px;
    padding: 25.77px 27.79px 27.99px 19.06px;
    display: flex;
    flex-direction: column;
    gap: 26.83px;

    @include for-size(sm) {
      width: 100%;
    }

    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include for-size(ms) {
        flex-direction: column;
        align-items: flex-start;
      }

      div {
        display: flex;
        flex-direction: column;

        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #8391a2;
        }
      }

      input {
        box-sizing: border-box;
        padding: 10px 14px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #101828;

        @include for-size(ms) {
          width: 100%;
        }

      }
    }
  }
}