.evidenceWrapper {
    height: 94.21px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        color: #98A2B3;         //--Gray--400
    }
}