.disputeLostWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 99.11px 0 114.16px;

    .disputeLost {
        max-width: 285px;
        width: 95%;

        .underLost {
            text-align: center;

            img {
                max-width: 100%;
            }
        }

    }

    .statusStatement {
        margin-top: 46.33px;

        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;

            margin: 0 auto;

            max-width: 233.03px;
            text-align: center;
            color: #101828; //--gray--900
        }

        h3 {
            padding: 4px 12px;
            border-radius: 16px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            width: max-content;

            margin: 11.31px auto 0;

            color: #F04438; //--error--500
            background: #FEF3F2; //--error--50
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            margin-top: 16px;

            text-align: center;
            color: #1D2939; //--gray--800
        }
    }


    .appeal {
        margin-top: 1rem;
        text-align: center;

        Button {
            text-transform: capitalize;
            border-radius: 8px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }
    }
}